import { Box, Grid, IconButton } from "@mui/material";
import locationIcon from "../../../assets/images/Discovery.svg";
import clockIcon from "../../../assets/images/clock.svg";
const Service = ({ setmodelShow, seteditMode, setEditId, setDeleteModal, setserviceForm, isServices}) => {

    return(
        <>
            {
                <Box className="mt-5">
                    <Box>
                        <Grid container spacing={2}>
                            {isServices?.map((service, i) => (
                                <Grid item xs={12} sm={6} lg={4} key={i}>
                                    <Box className="border-gred">
                                        <Box className="inner-gred">
                                            <Box className="d-flex align-items-center justify-content-between">
                                                <h4 className="input-label" style={{
                                                    wordWrap: "normal",
                                                    width: "85%",
                                                    textWrap: "wrap",
                                                    wordBreak: "break-word",
                                                }}>
                                                    {service?.service_name}
                                                </h4>
                                                <Box className="d-flex">
                                                    <IconButton
                                                        onClick={() => {
                                                            setserviceForm({
                                                                service: service?.service_name,
                                                                details: service?.service_type,
                                                                details_link: service?.link,
                                                                location: service?.location_type,
                                                                adress: service?.address,
                                                                time: "",
                                                                session_price: service?.session_price,
                                                                member: service?.participants,
                                                                meximum_member: service?.maximum_participants,
                                                                addition_price: service?.additional_price,
                                                                discription: service?.description,
                                                                locationCordinates: service?.location,
                                                                session_hours: service?.session_hour,
                                                                session_minutes: service?.session_minute,
                                                            });
                                                            setmodelShow(true);
                                                            seteditMode(true);
                                                            setEditId(service?._id);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M15.3334 5.16644C14.0835 5.58308 12.4169 3.91654 12.8336 2.66663M12.7325 2.76773L10.0396 5.46054C8.86897 6.63122 8.03847 8.09805 7.63693 9.7042L7.50681 10.2247C7.46627 10.3868 7.61316 10.5337 7.77533 10.4932L8.2958 10.3631C9.90195 9.96153 11.3688 9.13103 12.5395 7.96035L15.2323 5.26754C15.5638 4.93604 15.75 4.48644 15.75 4.01763C15.75 3.0414 14.9586 2.25 13.9824 2.25C13.5136 2.25 13.064 2.43623 12.7325 2.76773Z"
                                                                stroke="#363853"
                                                            />
                                                            <path
                                                                d="M9 2.25C8.2325 2.25 7.46501 2.33822 6.71282 2.51466C4.62976 3.00328 3.00328 4.62975 2.51466 6.71282C2.16178 8.21719 2.16178 9.78281 2.51466 11.2872C3.00328 13.3702 4.62976 14.9967 6.71283 15.4853C8.21719 15.8382 9.78281 15.8382 11.2872 15.4853C13.3702 14.9967 14.9967 13.3702 15.4853 11.2872C15.6618 10.535 15.75 9.76748 15.75 8.99998"
                                                                stroke="#363853"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{padding: "0"}}
                                                        onClick={() => {
                                                            setDeleteModal((pre) => ({
                                                                ...pre, service: true,
                                                            }));
                                                            setEditId(service?._id);
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                                                fill="#FFD8D8"
                                                            />
                                                            <path
                                                                d="M9.17 14.8299L14.83 9.16992"
                                                                stroke="#FF0000"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M14.83 14.8299L9.17 9.16992"
                                                                stroke="#FF0000"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <span>{service?.service_type}</span>
                                            <hr/>
                                            <Box>
                                                {service?.address &&
                                                    <Box className="d-flex gap-2 align-items-center">
                                                        <img src={locationIcon} alt="clock_Icon"/>
                                                        <span style={{fontWeight: "600"}}>
                                                            {service?.address}
                                                        </span>
                                                    </Box>}
                                                {
                                                    service?.details_link && <>link</>
                                                }
                                                {(service?.session_hour || service?.session_minute) &&
                                                    <Box className="d-flex gap-2 align-items-center">
                                                        <img src={clockIcon} alt="clock_Icon"/>
                                                        <span style={{fontWeight: "600"}}>
                                                            {`${service?.session_hour}:${service?.session_minute} Hours`}
                                                        </span>
                                                    </Box>}
                                            </Box>
                                            <Box className="d-flex gap-5 mt-3">
                                                <Box>
                                                    <Box>
                                                        <span className="grey-text">Session Price</span>
                                                        <p style={{margin: "0"}}>
                                                            ${service?.session_price || 0}
                                                        </p>
                                                    </Box>
                                                    <Box className="mt-2">
                                                                   <span className="grey-text">
                                                                       Additional Price
                                                                   </span>
                                                        <p style={{margin: "0"}}>
                                                            ${service?.additional_price || 0}
                                                        </p>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box>
                                                        <span className="grey-text">Participants</span>
                                                        <p style={{margin: "0"}}>
                                                            {service?.participants || 0}
                                                        </p>
                                                    </Box>
                                                    <Box className="mt-2">
                                                                    <span className="grey-text">
                                                                      Maximum Participants
                                                                    </span>
                                                        <p style={{margin: "0"}}>
                                                            {service?.maximum_participants || 0}
                                                        </p>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <hr/>
                                            <span className="grey-text"
                                                  style={{wordWrap: "break-word"}}>{service?.description}</span>
                                        </Box>
                                    </Box>
                                </Grid>))}
                        </Grid>
                    </Box>
                </Box>}
        </>
    )
}

export default Service;