import { CloseOutlined } from "@ant-design/icons";
import { Box, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from 'react';
import { BASE_URL } from "../../Configration";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: '17px 31px',
    maxWidth: '460px'
};

const ClaimSheetModel = ({ getalldata,show, handleClose, setpopup, setuserdata, file, setFile}) => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const [isDragOver, setIsDragOver] = useState(false);
    const [error, setError] = useState("")
    const [loader, setLoader] = useState(false)
    const handleAddTax = (file) => {
        setLoader(true)
        const data = new FormData();
        data.append('excel_file', file);

        try {
            fetch(`${BASE_URL}/claim_provider/import_excel`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.token}`
                },
                body: data
            })
                .then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData, 'dataht');
                    setpopup({ show: true, message: responseData.message, success: responseData.success });

                    if (responseData.success) {
                        setLoader(false)
                        const newTaxData = responseData.data; // Assuming responseData.data contains the new tax data

                        // Update userdata in state
                        setuserdata(prevData => {
                            const newData = [...prevData, newTaxData];
                            localStorage.setItem('add_Claim_Users', JSON.stringify(newData));
                            return newData;
                        });
                        getalldata()

                        handleClose();
                    }else {
                        setLoader(false)
                    }
                });
        } catch (err) {
            setLoader(false)
            console.log(err);
        }
    };

    const handleFileUpload = () => {
        if (file) {
            handleAddTax(file);
        } else {
            console.error('No file selected');
        }
    };


    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (droppedFile && allowedTypes.includes(droppedFile.type)) {
            setError("")
            setFile(droppedFile);
        }else {
            setFile("");
            setError("Invalid file type. Please select an Excel file.")
        }

    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setError("")
            setFile(selectedFile);
        }else {
            setFile("");
            setError("Invalid file type. Please select an Excel file.")
        }
    };

    const handleBrowseClick = () => {
        document.getElementById('fileInput').click();
    };


    return (
        <>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mb-0" style={{fontWeight: '600'}}>
                            Import Excel Sheet
                        </h6>
                        <IconButton className="bg-light-gray" onClick={() => handleClose()}>
                            <CloseOutlined/>
                        </IconButton>
                    </div>

                    <div
                        className={`drop-area ${isDragOver ? 'dragover' : ''} mt-4`}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={handleBrowseClick}
                    >
                        <p>Drag and drop your file here or click to browse</p>
                        <input
                            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file" id="fileInput" onChange={handleFileInputChange} />
                        {file && (
                            <div>
                                <h2>Uploaded File:</h2>
                                <p>{file.name}</p>
                            </div>
                        )}
                    </div>

                    <p className="t-15-500 text-danger">{error}</p>

                    <div className="btn_gred mt-4">

                        {loader ? (<a
                            href="#"
                            onClick={(e) => e.preventDefault()}
                            className="btn_admina "
                        >
                            {/* save */}
                            <div className="loader"></div>
                        </a>) : (
                            <button
                                // className="w-100 btn color-btn mt-4"
                                className="btn_admina "
                                    onClick={() => handleFileUpload()}>
                                Submit
                            </button>
                        )}
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default ClaimSheetModel;