// material-ui

import logo from 'assets/images/logo.png';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
   

    return (
      
        <>
            <img src={logo} alt="logo" width={'60%'} />
         
        </>
    );
};

export default Logo;
