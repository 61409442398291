import { useEffect, useState } from 'react';

import {  DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Divider, Empty, Popconfirm, Tooltip } from 'antd';
// import noimage from 'assets/images/usericon.png';
import { BASE_URL } from 'Configration';
import { ThreeDots } from 'react-loader-spinner';
import { activeItem } from 'store/reducers/menu';
import { Pagination } from '../../../../node_modules/@mui/material/index';
import { useDispatch } from '../../../../node_modules/react-redux/es/exports';
import { useNavigate, useParams } from '../../../../node_modules/react-router/dist/index';
import { toast } from '../../../../node_modules/react-toastify/dist/index';
import { sanitizeInput } from 'utils/password-strength';
// let keyword = '';
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     // border: '2px solid #000',
//     boxShadow: 24,
//     p: 4
// };

const Rolepage = () => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
  
    const [breadcrumb, setbreadcrumb] = useState(JSON.parse(sessionStorage.getItem('breadcrumb')));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [controller, setController] = useState(null);
  

    const handleOpen = () => {

       
      
            navigate('/addmembers');
     
            breadcrumbManager('Add Member', `/addmembers`);
        
    };
 
    const [userdata, setuserdata] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [searchchange, setsearchchange] = useState('');
    const [popup, setpopup] = useState({ show: false, message: '', success: true });

    const { number } = useParams();
    console.log(number);

    const [totalPage, settotalPage] = useState(1);
    const reportsdata = () => {
        if (token?.is_login && token?.is_login == true) {
            dispatch(activeItem({ openItem: ['util-roles'] }));
            if (controller) {
                controller.abort();
            }

            const newController = new AbortController();
            setController(newController);
            const userList = new FormData();

            {
                Number(number) ? userList.append('page', Number(number)) : userList.append('page', Number(1));
            }

            userList.append('search', searchchange.trim());
            userList.append('limit', 10);

            try {
                fetch(`${BASE_URL}/admin_role/member_list `, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    },
                    body: userList,
                    signal: newController.signal
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setIsLoader(false);
                            setuserdata(data.data);

                            const totalNumberOfData = data?.total_number_of_data || 0;
                            const totalPages = Math.ceil(totalNumberOfData / 10);
                            settotalPage(totalPages);
                            
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        reportsdata();

        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [number, searchchange]);

    useEffect(() => {
        if (popup.show) {
            setTimeout(() => {
                setpopup(false);
            }, 3000);
        }
    }, [popup]);

    useEffect(() => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == window.location.pathname) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            setbreadcrumb(JSON.parse(sessionStorage.getItem('breadcrumb')));
        } else {
            setbreadcrumb(bread);
            sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
        }
    }, []);
    // const [password, setPassword] = useState('***************');
    // const toggleVisibility = async (value) => {
    //     setVisiblity(!Visiblity);

    //     if (!Visiblity) {
    //         // Make API call only when toggling to "show"
    //         const userList = new FormData();
    //         userList.append('password', value?.password);

    //         try {
    //             const response = await fetch(`${BASE_URL}/admin_role/decrypt_password`, {
    //                 method: 'POST',
    //                 headers: {
    //                     Authorization: `Bearer ${token.token}`,
    //                 },
    //                 body: userList,
    //             });
    //             const result = await response.json();

    //             if (result.success) {
    //                 setPassword(result.data?.decrypted_password);
    //             } else {
    //                 console.error('Failed to decrypt password');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching password:', error);
    //         }
    //     } else {
    //         // Hide password when toggling off
    //         setPassword('***************');
    //     }
    // };
 
    const [passwordVisibility, setPasswordVisibility] = useState({});
console.log(passwordVisibility);


const [decryptedPasswords, setDecryptedPasswords] = useState({});
const togglePassword = async (data, show) => {
    // Toggle the visibility state for this specific user
    setPasswordVisibility((prev) => ({
        ...prev,
        [data._id]: show,
    }));
    console.log(decryptedPasswords[data._id] );

    if (show ) {
      const userList = new FormData();
      userList.append("password", data.password);
      userList.append("user_id", data._id);

      try {
        const response = await fetch(`${BASE_URL}/admin_role/decrypt_password`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token.token}`,
          },
          body: userList,
        });

        const result = await response.json();

        if (result.success && data._id === result?.data?.user_id) {
        
          setDecryptedPasswords((prev) => ({
            ...prev,
            [data._id]: result?.data?.decrypted_password,
          }));
        } else {
          console.error("Failed to decrypt password");
        }
      } catch (error) {
        console.error("Error fetching password:", error);
      }
    } else if (!show) {
    
      setDecryptedPasswords((prev) => ({
        ...prev,
        [data._id]: "***********",
      }));
    }
  };
   
    const breadcrumbManager = (a, b) => {
        let bread = [];
        let prepath = false;
        for (var i = 0; i < breadcrumb?.length; i++) {
            bread.push(breadcrumb[i]);
            if (breadcrumb[i].url == b) {
                prepath = true;
                break;
            }
        }
        if (prepath == false) {
            bread.push({ name: a, url: b });
        }

        sessionStorage.setItem('breadcrumb', JSON.stringify(bread));
    };
    const deleteIssue = (a) => {
        if (token?.is_login && token?.is_login == true) {
          const apiData = new FormData();
          apiData.append("user_id", a);
       
          try {
            fetch(`${BASE_URL}/admin_role/delete_member`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token.token}`,
              },
              body: apiData,
            })
              .then((response) => response.json())
              .then((data) => {
              
                if (data.success) {
                  toast.success(data?.message);
                  const val = userdata?.filter((issue) => {
                    return issue._id !== a;
                  });
                  setuserdata(val);
                //   setroleData(val);
                //   setdeleteModal(false);
                //   emptyField()
                } else {
                  toast.error(data?.message);
                }
              });
          } catch (err) {
            console.log(err);
          }
        } else {
          navigate("/login");
        }
      };
    return (
        <>
            <div className="breadcrumb d-flex align-items-center">
                <p style={{ color: '#000000' }}>{'Roles'}</p>
            </div>
            <div className="col-lg-12 d-flex flex-wrap align-items-center mb-3">
                <div className="col-lg-6 p-2 position-relative flex-lg-grow-0 flex-grow-1">
                    <input
                        className="search-input"
                        placeholder="Search here"
                        value={searchchange}
                        onChange={(e) => {
                            setsearchchange(sanitizeInput(e.target.value));
                            navigate(`/roles/1`);
                        }}
                    />
                    <div className="search-icon">
                        <SearchOutlined />
                    </div>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Button
                        size="large"
                        onClick={handleOpen}
                        className="d-flex align-items-center ms-auto bg-white add-cattegory-button"
                        type="primary"
                        
                    >
                        <span className="ms-1">Add Member</span>
                    </Button>
                </div>
            </div>
            <Divider orientation="left" className="my-2">
            Roles
            </Divider>
            {isLoader ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10%'
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#0dbcec"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ color: 'linear-gradient(180deg, #0dbcec, #ffc056)}' }}
                        wrapperClass=""
                    />{' '}
                </div>
            ) : (
                <div className="p-2">
                    {userdata?.length > 0 ? (
                        <TableContainer component={Paper} className="mb-4">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="bg-light-gray">
                                        <TableCell className="bh-line">Name</TableCell>
                                        <TableCell className="bh-line">Email Id</TableCell>
                                        <TableCell className="bh-line" >
                                            Password
                                        </TableCell>
                                        <TableCell className="bh-line" style={{width:"30%"}}>Roles</TableCell>
                                        <TableCell className="bh-line">Created Date & Time</TableCell>
                                        <TableCell className="bh-line">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userdata?.length > 0 &&
                                        userdata.map((data, i) => (
                                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell
                                                    className="br-line"
                                                    component="th"
                                                    scope="row"
                                                    // sx={{ cursor: 'pointer' }}
                                                    // onClick={() =>
                                                    //     navigate(
                                                    //         data?.user_id?.user_type == 'provider'
                                                    //             ? `/providerdetails/${data?._id}`
                                                    //             : `/providerdetails/${data?._id}`
                                                    //     )
                                                    // }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        {/* <div className="position-relative">
                                                            <div className="profile-img-table me-3">
                                                                <img
                                                                    src={`${data?.profile_picture}` ?data?.profile_picture: noimage}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = noimage;
                                                                    }}
                                                                    alt="profile"
                                                                    width="100%"
                                                                    className="object-fit"
                                                                />
                                                            </div>
                                                            {data.is_verified && (
                                                                <>
                                                                    <div className="verified-user">
                                                                        <CheckCircleFilled />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div> */}
                                                        <div className="table-p">
                                                            <p className="m-0">{data?.name}</p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell className="br-line">
                                                    {data?.email_address == undefined ? '-' : data?.email_address || '-'}
                                                </TableCell>
                                                <TableCell className="br-line">
                                                {/* <div className="d-flex justify-content-between" key={data?._id}>
            <div>{password}</div>
            <div className="d-flex justify-content-end align-items-end">
                {Visiblity ? (
                   <VisibilityOutlinedIcon
                   onClick={()=>{toggleVisibility(data )}}
                       style={{ color: '#38afc7', cursor: 'pointer' }}
                   /> 
                ) : (
                   
                    <VisibilityOffOutlinedIcon
                    onClick={()=>{toggleVisibility(data )}}
                    style={{ color: '#38afc7', cursor: 'pointer' }}
                />
                )}
            </div>
        </div> */}
       <div className="d-flex justify-content-between" key={data?._id}>
            <div>  {passwordVisibility[data._id]
              ? decryptedPasswords[data._id] 
              : "***********"}</div>

            <div className="d-flex justify-content-end align-items-end">
        
            {passwordVisibility[data._id] ? (
                <VisibilityOutlinedIcon
                onClick={() => togglePassword(data, false)}
                style={{ color: "#38afc7", cursor: "pointer" }}
              />
            ) : (
                <VisibilityOffOutlinedIcon
                onClick={() => togglePassword(data, true)}
                style={{ color: "#38afc7", cursor: "pointer" }}
              />
            
            )}
            </div>
        </div>
                                                </TableCell>
                                                <TableCell className="br-line">
                                                <div className=' d-flex  flex-wrap ' >
                                                    {/* {Object.entries(data?.roles || {})
                                                        .filter(([value]) => value == true)
                                                        .map(([key]) => (
                                                           <button  className='btnbg me-2 mt-2' style={{borderRadius:"5px " ,padding:"0px  5px 0px 5px" }} key={key}>   {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}</button >
                                                        ))} */}
                                                        {Object.entries(data?.roles || {})
    .filter(([, value]) => Boolean(value)) // Ensures correct boolean check
    .map(([key]) => (
        <button  
            className='btnbg me-2 mt-2' 
            style={{ borderRadius: "5px", padding: "0px 5px" }} 
            key={key}
        >   
            {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
        </button>
    ))
}


                                                        </div>
                                                </TableCell>
                                                <TableCell className="br-line">
                                                    {new Date(data?.createdAt)?.toDateString() || '-'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip placement="top" title={'Edit Role'}>
                                                        <div
                                                            className="me-2 action-button bg-white"
                                                            onClick={() => {
                                                                console.log(data);

                                                                // setEditedData(data);
                                                                // setIsEdit(true);
                                                                navigate(`/addmembers/${data?._id}`);
     
                                                                breadcrumbManager('Add Member', `/addmembers`);
                                                            }}
                                                            style={{ border: '1px solid #1677ff', color: '#1677ff' }}
                                                        >
                                                            <EditOutlined />
                                                        </div>
                                                    </Tooltip>

                                                    <Popconfirm
                                                        title="Are you sure to delete role?"
                                                        description="Are you sure to delete this role??"
                                                        onConfirm={() => deleteIssue(data._id)}
                                                        onCancel={() => {}}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip placement="top" title={'Delete Role?'}>
                                                            <div
                                                                className="action-button bg-white"
                                                                style={{ border: '1px solid #ff4d4f', color: '#ff4d4f' }}
                                                            >
                                                                <DeleteOutlined />
                                                            </div>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Empty />
                    )}
                </div>
            )}
            {totalPage > 1 && (
                <>
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        color="primary"
                        className="my-5 d-flex justify-content-center"
                        page={parseInt(number) || 1}
                        onChange={(event, number) => {
                            console.log(number);

                            navigate(`/roles/${number}`);
                        }}
                    />
                </>
            )}

            {popup.show && (
                <Alert
                    message={popup?.message}
                    type={popup?.success ? 'success' : 'error'}
                    showIcon
                    style={{ position: 'fixed', right: '15px', bottom: '30px' }}
                />
            )}
            {/* <SupportPopup setOpen={setOpenReport} open={openReport} supportid={reportId} name={Report}></SupportPopup> */}
        </>
    );
};

export default Rolepage;
