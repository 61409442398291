import React, { useEffect } from 'react'

import menuItems from 'menu-items/index';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';

function Accesspage() {
    const navigate = useNavigate();
    const userData = localStorage.getItem('adAnimaLogin');
    
    useEffect(()=>{
        if (userData) {
          console.log(JSON.parse(userData));
          if (JSON.parse(userData).user_type === 'sub_admin') {
              const userRole = JSON.parse(userData).roles;
    const tabsitems =[...menuItems.items[0].children,...menuItems.items[1].children];
              for (const items of tabsitems) {
                console.log(items);
                
                  if (userRole[items.subname]) {
                    
                    console.log(items.url);
                    
                      navigate(`${items.url}`);
                      break;
                  }
              }
          
          }else if(JSON.parse(userData).user_type === 'admin'){
            navigate('/dashboard');
          }
      }
      else{
        navigate('/login')
      }
      },[])
  return (
    <div>
      
    </div>
  )
}

export default Accesspage
