// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { createContext, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-multi-carousel/lib/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { io } from 'socket.io-client';
import { BASE_URL_V8 } from 'Configration';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
export const DomainContext = createContext();
export const PadinationContext = createContext();

export const socket = io(`${BASE_URL_V8}`, {
    autoConnect: false
});
const App = () => {
    const [pagination, setpagination] = useState({
        category: 1,
        users: 1,
        provider: 1,
        events: 1,
        transactions: 1,
        shippingProduct: 1
    });
    // const socket = io('your-socket-server-url', {
    //     transports: ['websocket'], // Force WebSocket transport
    //     reconnection: true,
    //     reconnectionAttempts: 5,
    //     reconnectionDelay: 1000,
    // });

    useEffect(() => {
        socket.on("connect", () => {
            console.log("✅ Socket connected:", socket?.id);
        });
    
   
        const loginData = JSON.parse(localStorage.getItem('adAnimaLogin'));
        console.log(loginData);

        socket.connect();

        socket.on("connect", () => {
            console.log("✅ Socket connected:", socket?.id);

            // Emit user details to the server
            socket.emit("setSocketId", {
                user_id: loginData?._id,
                device_token:"sdgdsfgfgredfgsfdgfdgdgdfgsgfsdgdfgfdg",
            });

           
        });

      


     
    }, []);
    return (
        <>
            <PadinationContext.Provider value={{ pagination, setpagination }}>
                <ThemeCustomization>
                    <ScrollTop>
                        <Routes />
                    </ScrollTop>
                </ThemeCustomization>
            </PadinationContext.Provider>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
            />
        </>
    );
};

export default App;
