import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const PlacePopUp = ({ open, setOpen, value }) => {
    const [location, setLocation] = useState(value || '');
   
    const [error, setError] = useState(null);

    return (
        <>
            <Modal
                centered
                show={open}
                onHide={() => {
                    setOpen(false);
                }}
                dialogClassName="delete-modal"
            >
                <div className="Congratulations_msg">
                    <div style={{ padding: '10px' }}>
                        {/* Other modal content */}

                        <PlacesAutocomplete
                            value={location}
                            onChange={(e) => {
                                setLocation(e);
                                // Other logic...
                            }}
                            onSelect={(address) => {
                                geocodeByAddress(address)
                                    .then((results) => getLatLng(results[0]))
                                    .then(() => {
                                        setLocation(address);
                                        // setCenter({
                                        //     lat: latLng.lat,
                                        //     lng: latLng.lng,
                                        // });
                                        // setFirstTime(false);
                                    })
                                    .catch((error) => {
                                        console.error('Error with PlacesAutocomplete:', error);
                                        setError('There was an error with location selection. Please try again.');
                                    });
                            }}
                        >
                         
                        </PlacesAutocomplete>

                        {/* Error handling */}
                        {error && (
                            <div className="error-message">
                                {error}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default PlacePopUp;
