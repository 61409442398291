import { useEffect, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import { toast } from '../../../../node_modules/react-toastify/dist/index';
import { BASE_URL } from "../../../Configration";
import PlacePopUp from "./PlacePopUp";

const DescSelector = ({inputData, setregistration, registration}) => {
    const token = JSON.parse(localStorage.getItem('adAnimaLogin'));
    const [allDescription, setAllDescription] = useState([]);
    const [descName, setDescName] = useState("")
    const [isLocation, setIsLocation] = useState(false)
    // const [registration, setregistration] = useState({
    //     provider_services: [],
    //     address: "",
    //     latlong: {}
    // });
    const [serviceOpen, setserviceOpen] = useState(false);
    // const [firstTime, setfirstTime] = useState(true);

    useEffect(() => {
        if (token?.is_login && token?.is_login == true) {

            try {
                fetch(`${BASE_URL}/service/description_list`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.token}`
                    }

                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setAllDescription(data?.data)
                        }else if (data.statuscode == 101) {
                            localStorage.clear();
                            toast.error(data?.message);
                            window.location.reload();
                            // navigate('/login');
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }, []);
    const DescFilter = () => {
        const filterMap = allDescription?.filter((e) => {
            if (e?.description_name?.toLocaleLowerCase()?.includes(descName?.toLocaleLowerCase())) {
                return e
            }
        })
        return filterMap
    }
console.log(inputData);

    useEffect(() => {
        const SERVICEARRAY = []
        inputData?.provider_services?.map((ele) => (
            SERVICEARRAY.push(ele?._id)
        ))

        setregistration((pre) => ({...pre, provider_services: SERVICEARRAY}))

    }, [inputData])
console.log(registration);

    useEffect(() => {
        if (inputData?.is_other_desc) {
            setregistration((pre) => ({...pre, is_other_desc: true}))
        }
    }, [inputData])

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-4 p-3 homepadding" style={{zIndex: "12"}}>
                    <div className="position-relative">
                        <div className="group-input" style={{marginTop: '9px'}}>
                            <input
                                type="text"
                                name=""
                                placeholder={`${registration.provider_services.length ? `${registration.provider_services.length} selected Services` : "Select Description  Name"} `}
                                className="select-servcesss map-select"
                                onChange={(e) => {
                                    setserviceOpen(true)
                                    setDescName(e.target.value)

                                }}
                                onClick={() => {
                                    setserviceOpen(!serviceOpen);
                                    // setfirstTime(false)
                                }}
                            />
                        </div>
                        {/*<div*/}
                        {/*    className={serviceOpen ? "list-open open" : "list-open"}*/}
                        {/*    style={{scrollBehavior: "smooth"}}*/}
                        {/*>*/}
                        {/*    {DescFilter()?.map((data) => {*/}
                        {/*        return (*/}
                        {/*            <>*/}
                        {/*                <div className="group-input-check position-relative my-2">*/}
                        {/*                    <div className="">*/}
                        {/*                        <label className="gred-checkbox">*/}
                        {/*                            {data?.description_name}*/}
                        {/*                            <input*/}
                        {/*                                type="checkbox"*/}
                        {/*                                checked={registration.provider_services.includes(*/}
                        {/*                                    data._id*/}
                        {/*                                )}*/}
                        {/*                                onClick={() => {*/}
                        {/*                                    let available = false;*/}
                        {/*                                    let newValue =*/}
                        {/*                                        registration?.provider_services?.filter(*/}
                        {/*                                            (value) => {*/}
                        {/*                                                if (*/}
                        {/*                                                    registration?.provider_services.includes(*/}
                        {/*                                                        data._id*/}
                        {/*                                                    )*/}
                        {/*                                                ) {*/}
                        {/*                                                    available = true;*/}
                        {/*                                                }*/}
                        {/*                                                if (value !== data._id) {*/}
                        {/*                                                    return value;*/}
                        {/*                                                }*/}
                        {/*                                            }*/}
                        {/*                                        );*/}
                        {/*                                    if (available) {*/}
                        {/*                                        setregistration({*/}
                        {/*                                            ...registration,*/}
                        {/*                                            provider_services: newValue,*/}
                        {/*                                        });*/}
                        {/*                                    } else {*/}
                        {/*                                        setregistration({*/}
                        {/*                                            ...registration,*/}
                        {/*                                            provider_services: [*/}
                        {/*                                                ...registration.provider_services,*/}
                        {/*                                                data._id,*/}
                        {/*                                            ],*/}
                        {/*                                        });*/}
                        {/*                                    }*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                            <span className="checkmark"></span>*/}
                        {/*                        </label>*/}
                        {/*                    </div>*/}
                        {/*                    /!* <h4 className="float-end">*/}
                        {/*      {data?.admin_service_amount}*/}
                        {/*    </h4> *!/*/}
                        {/*                </div>*/}
                        {/*            </>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</div>*/}


                        <div
                            className={serviceOpen ? "list-open open" : "list-open"}
                            style={{scrollBehavior: "smooth"}}
                        >
                            {DescFilter()?.map((data,i) => {
                                return (<>
                                    <div className="group-input-check position-relative my-2" key={i}>
                                        <div className="">
                                            {data?.description_name == "Other" ? (<>
                                                <label className="gred-checkbox">
                                                    {data?.description_name}
                                                    <input
                                                        type="checkbox"
                                                        checked={registration.provider_services.includes(data._id)}
                                                        onClick={() => {
                                                            let available = false;
                                                            let newValue = registration?.provider_services?.filter((value) => {
                                                                if (registration?.provider_services.includes(data._id)) {
                                                                    available = true;
                                                                }
                                                                if (value !== data._id) {
                                                                    return value;
                                                                }
                                                            });
                                                           
                                                            if (available) {
                                                                setregistration({
                                                                    ...registration,
                                                                    provider_services: newValue,
                                                                    is_other_desc: false,
                                                                    // other_desc: "",
                                                                });
                                                                console.log({
                                                                    ...registration,
                                                                    provider_services: newValue,
                                                                    is_other_desc: false,
                                                                    // other_desc: "",
                                                                }, 'jhfkjhgkjhgjk')
                                                            } else {
                                                                // if (
                                                                //   registration?.provider_services
                                                                //     ?.length < 7
                                                                // ) {
                                                                setregistration({
                                                                    ...registration,
                                                                    provider_services: [...registration.provider_services, data._id,],
                                                                    is_other_desc: true,
                                                                });
                                                                console.log({
                                                                    ...registration,
                                                                    provider_services: [...registration.provider_services, data._id,],
                                                                    is_other_desc: true,
                                                                }, 'jhfkjhgkjhgjk')
                                                                // } else {
                                                                //   toast.error(
                                                                //     "You can't select more than 7 services."
                                                                //   );
                                                                // }
                                                            }
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                {registration?.is_other_desc && (<>
                                                    <div
                                                        className="group-input"
                                                        style={{
                                                            margin: "10px 0 10px 0",
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            style={{padding: "15px"}}
                                                            required
                                                            value={registration.other_desc}
                                                            onChange={(e) => {
                                                                setregistration({
                                                                    ...registration, other_desc: e.target.value,
                                                                });
                                                            }}
                                                            placeholder="Other Discription"
                                                        />
                                                    </div>
                                                </>)}
                                            </>) : (<>
                                                <label className="gred-checkbox">
                                                    {data?.description_name}
                                                    <input
                                                        type="checkbox"
                                                        checked={registration.provider_services.includes(data._id)}
                                                        onClick={() => {
                                                            let available = false;
                                                            let newValue = registration?.provider_services?.filter((value) => {
                                                                if (registration?.provider_services.includes(data._id)) {
                                                                    available = true;
                                                                }
                                                                if (value !== data._id) {
                                                                    return value;
                                                                }
                                                            });
                                                            if (available) {
                                                                setregistration({
                                                                    ...registration, provider_services: newValue,
                                                                });
                                                            } else {
                                                                // if (
                                                                //   registration?.provider_services
                                                                //     ?.length < 7
                                                                // ) {
                                                                setregistration({
                                                                    ...registration,
                                                                    provider_services: [...registration.provider_services, data._id,],
                                                                });
                                                                // } else {
                                                                //   toast.error(
                                                                //     "You can't select more than 7 services."
                                                                //   );
                                                                // }
                                                            }
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </>)}
                                        </div>
                                        {/* <h4 className="float-end">
                              {data?.admin_service_amount}
                            </h4> */}
                                    </div>
                                </>);
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 p-3 homepadding">
                    <div className="group-input mt-2 p-15 clearfix">
                        {/* <input
                            type="text"
                            readOnly
                            disabled
                            // value={serviceForm.session_price}
                            // onChange={(e) => {
                            //     if (e.target.value.length <= 6) {
                            //         setserviceForm({
                            //             ...serviceForm, session_price: e.target.value,
                            //         });
                            //     }
                            // }}
                            onClick={() => setIsLocation(true)}
                            placeholder="Set Location"
                        /> */}
                        <PlacesAutocomplete
                            value={registration.adress}
                            onChange={(e) => {
                                setregistration({
                                    ...registration, adress: e,
                                });
                            }}
                            onSelect={(address) => {
                                geocodeByAddress(address)
                                    .then((results) => getLatLng(results[0]))
                                    .then((latLng) => {
                                        setregistration({
                                            ...registration, adress: address, locationCordinates: {
                                                type: "Point", coordinates: [latLng.lng, latLng.lat],
                                            },
                                        });
                                    })
                                    .catch((error) => console.error("Error", error));
                            }}
                        >
                            {({
                                  getInputProps, suggestions, getSuggestionItemProps, loading,
                              }) => (<div>
                                <input
                                    required
                                    {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions?.map((suggestion,i) => {
                                        const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active ? {
                                            backgroundColor: "#fafafa", cursor: "pointer",
                                        } : {
                                            backgroundColor: "#ffffff", cursor: "pointer",
                                        };
                                        return (<div
                                        key={i}
                                            {...getSuggestionItemProps(suggestion, {
                                                className, style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>);
                                    })}
                                </div>
                            </div>)}
                        </PlacesAutocomplete>
                    </div>
                </div>
            </div>
            <PlacePopUp setOpen={setIsLocation} open={isLocation} setDetails={setregistration}
                        value={registration?.address}/>
        </>
    )
}

export default DescSelector;